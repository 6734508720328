import React from 'react'
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components'

const StyledRow = styled.div`
  width: 100%;
  height: 100vh;
  background: ${props => props.background};
  /* padding: 20px; */
  font-size: 20px;
`

const FeaturesStory = () => {
  return (
    <Controller>
      <Scene duration={1000} triggerHook="onLeave" pin>
        <StyledRow background="#dfdfdf">
          <h2>Feature 1</h2>
        </StyledRow>
      </Scene>
      <Scene duration={1000} triggerHook="onLeave" pin>
        <StyledRow background="#ccc">
          <h2>Feature 2</h2>
        </StyledRow>
      </Scene>
    </Controller>
  )
}

export default FeaturesStory
