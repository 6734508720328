import React from 'react'
import FeaturesStory from '../components/animated-components/features-story/features-story'
import Layout from '../components/resuable/layout/layout'

const NewFeatures = () => {
  return (
    <div>
      <FeaturesStory />
    </div>
  )
}

export default NewFeatures
